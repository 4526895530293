<template>
  <div>
    <div v-if="mode === 'DESIGN'">
      <el-select class="max-fill" v-if="!expanding" size="medium" multiple v-model="_value" disabled :placeholder="$t(placeholder)"/>
      <el-checkbox-group v-else v-model="_value">
        <el-checkbox disabled v-for="(op, index) in options" :key="index" :label="op">{{op}}</el-checkbox>
      </el-checkbox-group>
    </div>
    <div v-else>
      <template v-if="!readerMode">
        <el-select class="max-fill" v-if="!expanding" v-model="_value" multiple size="medium" clearable :placeholder="$t(placeholder)">
          <el-option v-for="(op, index) in options" :key="index" :value="op" :label="op"></el-option>
        </el-select>
        <el-checkbox-group v-else v-model="_value">
          <el-checkbox v-for="(op, index) in options" :key="index" :label="op">{{op}}</el-checkbox>
        </el-checkbox-group>
      </template>
      <template v-else>
        <span>{{ readerValue }}</span>
      </template>
    </div>
  </div>
</template>

<script>
import componentMinxins from '../ComponentMinxins'

export default {
  mixins: [componentMinxins],
  name: "MultipleSelect",
  components: {},
  props:{
    placeholder:{
      type: String,
      default: 'formItem.selectPlaceholder'
    },
    value:{
      type: Array,
      default: () => {
        return []
      }
    },
    expanding:{
      type: Boolean,
      default: false
    },
    options:{
      type: Array,
      default: () => {
        return []
      }
    },
    readerMode: {
      type: Boolean,
      default: false,
    },
  },
  computed:{
    readerValue() {
      return this.value.join(", ")
    }
  },
  data() {
    return {
      checks:[]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
